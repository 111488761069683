import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box, Button, ResponsiveImage, Title } from '../theme/theme';
import settings from "../settings.json"
import { Link } from 'react-router-dom';
// TODO: implement

const Papers = () => {
    

    useEffect(()=>{
    
    })

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={false}>
                    <Box>
                        <Grid item md={0}>
                            <Title>
                                Dear supervisors, you can find an overview of what I am working on &nbsp;
                                <a style={{textDecoration: "underline"}} href='https://docs.google.com/document/d/1F68GAVWP84i4I9XRV8RNLEkFFbwq0RwiF_5FIY3LD1g/edit?usp=sharing'>using this link</a>
                            </Title>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={0} sm={2} ></Grid>
                <Grid item sm >
                    <Box>
                        {/* <ResponsiveImage src={chameleon} /> */}
                    </Box>
                </Grid>
            </Grid>
        </Container>   
    )
}
export default Papers;
