export const graphicsQuery = `
*[_type == "graphic"]{
  _id,
  "url" : img.asset->url,
  "related" : *[_type != "settings" && references(^._id)]  
}

`

export const settingsQuery = `
*[_type == "settings"][0]{
  "header" : header.asset->url,
  intro,
  "images" : homepageimages[]._ref,
  calendar[]{
      title,
      _key,
      url,
      target->{ _type, "slug": slug.current }
  },
  news[]{
    _key,
    title,
    description,
    "graphic" : graphic._ref,
    buttons[]{
      title,
      _key,
      url,
      target->{ _type, "slug": slug.current }
      }
    },
  footer,
  socials,
publicationsText,
publicationButtons[]{
  title,
  _key,
  url,
  target->{ _type, "slug": slug.current }
},
  publications[]->
    {
      title,
      "slug": slug.current,
      description,
      abstract,
      "images" : projectimages[]._ref,
      collaborators[]->{title, url},
      buttons[]{
        title,
        _key,
        url,
        target->{ _type, "slug": slug.current }
      },
    },
 projectsText,
 projectButtons[]{
  title,
  _key,
  url,
  target->{ _type, "slug": slug.current }
},
  projects[]->
  {
    title,
    banner,
    "slug": slug.current,
    _id,
    year,
    "categories" : CategoryRef[]->title,
    "header" : header.asset->url,
    description,
    vimeolink,
    collaborators[]->{title, url},
    buttons[]{
      title,
      _key,
      url,
      target->{ _type, "slug": slug.current }
    },
    "images" : projectimages[]._ref,
    },
    contact
}
`

export const collaboratorsQuery = `
*[_type == "collaborator"]{
  _id,
  title,
  url,
}
`

export const categoryQuery = `
*[_type == "category"] | order(priority asc) {
  _id,
  title
}
`

