import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, MenuItem, SiteTitle, Socials, Social, ResponsiveImage } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid, Typography } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';

                    
const ImageChecker = ()=>{
    const client = useSelector(state=>state.data.client);
    // const members = useSelector(state=>state.data.members);
    // const [data, setData] = useState(null);
    const graphics = useSelector(state=>state.data.graphics)
    const settings = useSelector(state=>state.data.settings)

    useEffect(()=>{
        // if (!data && client){
        //     client.fetch(`*[_type == "graphic"]{
        //         _id,
        //         "url" : img.asset->url,
        //         "related" : *[_type != "settings" && references(^._id)]  
        //       }`)
        //     .then((newdata)=>{    
        //         setData(newdata);
        //     }).catch(error=>{ 
        //         console.log(error)
        //     })
        // }


        // if (!data && client){
        //     client.getHome()
        //     .then(newdata=>setData(newdata));
        // }
    })

    if (!graphics || !settings){
    return "waiting for data"
    }

    return <Grid container spacing={20}>
        {Object.values(graphics)
        .map((g)=>{

            var found = false;
            settings.images.forEach(element => {
                if (element == g._id){
                    found = true;
                }
            });

            console.log(found)
            
            return <Grid item key="" xs={12} lg={3}> 
            <ResponsiveImage src={g.url} />
            <Text>{g.home}</Text>
            <Text>
                <span style={{backgroundColor: found?"green":"red"}}>
                    {g._id}
                </span>
                </Text>

             </Grid>
        })}
    </Grid>

    return 
}



export default ImageChecker;
                    