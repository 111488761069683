import React from "react";
import { SiteTitle } from "../theme/theme";
import { Link } from "react-router-dom";

const Header = ()=>{
    return <Link  to="/"><div style={{textAlign: "center"}}>
        <SiteTitle>P S</SiteTitle>
    </div></Link>
}

export default Header;