import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, MenuItem, SiteTitle, Socials, Social } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid, Typography } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';

// TODO: implement

const SiteBuilder = ()=>{
    // const client = useSelector(state=>state.data.client);
    // const members = useSelector(state=>state.data.members);
    const graphics = useSelector(state=>state.data.graphics)
    const settings = useSelector(state=>state.data.settings)
    const collaborators = useSelector(state=>state.data.collaborators)

    useEffect(()=>{
        // if (!data && client){
        //     client.getHome()
        //     .then(newdata=>setData(newdata));
        // }
    })

    const Meta = <MetaTags>
                    <title>{"Pieter Steyaert / Home"}</title>
                    <meta name="description" content={"Pieter is an artist and researcher based in Copenhagen, Denmark, working at the intersection of art and science. He co-founded the collective SEADS, known for community art projects like Biomodd. Pieter is involved in projects such as sending an evolutionary artwork into space and co-directing immersive theatre experiences. He also develops online platforms, creates software for live visual performances, and is pursuing a PhD in facilitating collaborations between arts and sciences."} />
                    <meta property="og:title" content={"Pieter Steyaert / Home"} />
                </MetaTags>

    if (!graphics || !settings || !collaborators){
        // console.log(settings, graphics, collaborators)
        return "Data missing - see console"
    } else {
        return <div>
            <Title><a href="https://pietersteyaert.sanity.studio/" target="_blank">CMS</a></Title>
            

            <SiteTitle>P S</SiteTitle>
            <Title>I am a title</Title>
            <SubTitle>I am a subtitle</SubTitle>
            <Text>I am just regular old text</Text>
            <MenuItem>I am a menuitem</MenuItem>
            <Social>I am social</Social>
            
            
            <br/><br/><br/><br/><br/>

            <Text>
        <h1>Check data</h1>
        <h2>graphics</h2>
            {Object.entries(graphics).map(({url})=><img src={url} key={url} style={{width: "200px"}} />)}
        
        <h2>settings</h2>
            
            <img src={settings.header} style={{width: "100%"}} />
            
            <BlockContent blocks={settings.intro} />
            
            <h3>homepage images</h3>
            <ul>
                {settings.images.map(i=><li key={i}>{i}</li>)}
            </ul>

            <h3>calendar</h3>
            {settings.calendar.map(({_key, title, url, target})=>{
                return <div key={_key}>
                title ({title}), url ({url}), target({JSON.stringify(target)})    
                <br /><br />
                </div>})}
            
            <h3>news</h3>
            {settings.news.map(({_key, title, graphic, buttons})=>{
                return <div key={_key}>
                title ({title}), graphic ({graphic}), buttons({JSON.stringify(buttons)})    
                <br /><br />
                </div>})}
            
            <h3>footer</h3>
            <BlockContent blocks={settings.footer} />

            <h3>socials</h3>
            {settings.socials.map(({title, url})=><div key={title}> 
                {title} ({url})
            </div>)}
            
            <h3>publications</h3>
            {JSON.stringify(settings.publicationButtons)}
            <BlockContent blocks={settings.publications} />
            <ul>
                {settings.publications.map(i=><li key={i.slug}>{JSON.stringify(i)}</li>)}
            </ul>
            <BlockContent blocks={settings.publicationsText} />

            <h3>projects</h3>
            <BlockContent blocks={settings.projectsText} />
            {JSON.stringify(settings.projectButtons)}
            <ul>
                {settings.projects.map(i=><li key={i.slug}>{JSON.stringify(i)}</li>)}
            </ul>


        
        <h2>collaborators</h2>
        <ul>
            {collaborators.map(({_id, title, url})=><li key={_id}>id({_id}, {title}, {url}</li>)}
        </ul>
        </Text>
    </div>
    
    }

    
    
    // if (!data || !members){
    //     return <>
    //             {Meta}
    //             <Spinner />
    //         </>
    // } else {
    
    return "builder"
}



export default SiteBuilder;
