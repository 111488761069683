import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, CalendarItem, LookupResponsiveimage, ButtonList, getAcademicImages, blocksToText, LookupImageList, CollaboratorList, ResponsiveImage } from '../theme/theme';
import { useParams } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid, ImageList, ImageListItem, Typography } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';
import { Subtitles } from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Masonry from '@mui/lab/Masonry';

import Paper from '@mui/material/Paper';
import { useLocation } from 'react-router-dom';



const Project = (props)=>{

    const settings = useSelector(state=>state.data.settings);
    const location = useLocation();
    const [project, setProject] = useState(null);
    const params = useParams();

    useEffect(()=>{
        if ((settings && params && !project && params.slug)||(project && location.pathname !== "/project/"+project.slug)){
            var np = null;
            settings.projects.forEach(p => {
                if (p.slug === params.slug){
                    np = p
                }
            });
            setProject(np);
        }
    }, [location, settings, project, params])  

    if (settings){
        if (project){

            const Meta = <MetaTags>
                    <title>{project.title}</title>
                    <meta name="description" content={blocksToText(project.description)} />
                    <meta property="og:title" content={project.title} />
                </MetaTags>

            return <> 
            {Meta}
            <Grid container spacing={20}>
                <Grid item xs={12} sm={12} lg={6}>

                    <Title>⛉ {project.title}</Title>
                    
                    <Text><i> <BlockContent blocks={project.description} /> </i></Text>                    
                    {project.collaborators?<CollaboratorList collaborators={project.collaborators} />:null}
                    {project.vimeolink?<div style={{padding:"56.25% 0 0 0", marginTop: "20px", position:"relative"}}><iframe src={`${project.vimeolink}?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479`} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title=""></iframe></div>:null}
                    <br/>
                    <ButtonList buttons={project.buttons} />

                    <br/>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                {/* <ResponsiveImage src={project.header} /> */}
                <LookupImageList graphiclist={project.images} />
                </Grid>
                
                <Grid item xs={12} sx={{display: {xs : "none", sm: "none", md: "block"}}}>
                    
                </Grid>
                
                
                </Grid>


                {/* <HomeGraphicsMobile /> */}

            </>
        
        } else {
            return <Text>Can't find project</Text>
        }
    } else {
        return null
    }

}



export default Project;
