
import {createTheme, Typography, responsiveFontSizes, ImageList, ImageListItem } from "@mui/material";
import { useSelector } from 'react-redux';

import {default as MButton} from "@mui/material/Button"
import { Children } from "react";
import { Link } from "react-router-dom";

const defaultTheme = {
    palette: {
      type: 'light',
      // primary: {
      //   main: 'white',
      // },
      // secondary: {
      //   main: 'green',
      // },
    },
    typography: {
      fontFamily: 'Open Sans',
      fontSize: 12,
      fontWeight: 400,
      h1: {
        fontWeight: 800,
        fontSize: '30px',
      },
      h2: {
        fontSize: '18px',
        fontWeight: 700,
      },
      h3: {
        fontWeight: 1000,
        fontSize: '2.7rem',
      },
      h4: {
        fontWeight: 700, 
        fontSize: "30px"
      }
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: 'white'
          }
        }
      },
        MuiButton: {
          variants: [
            {
              props: { variant: 'hard' },
              style: {
                textTransform: 'none',
                border: `2px solid white`,
                borderRadius: "0px"
              },
            },
          ],
        },
      },
  };

  

const theme =  responsiveFontSizes(createTheme(defaultTheme));


//   typography
export const SiteTitle = ({children})=>{
  return <Typography variant="h1" style={{fontWeight: "800", fontSize: "182px"}} >{children}</Typography>
}

export const Title = ({children})=>{
    return <Typography variant="h1" style={{fontWeight: "800", fontSize: "40px"}}>{children}</Typography>
}

export const SubTitle = ({children})=>{
    return <Typography variant="h2" style={{fontWeight: "700", fontSize: "28px", fontStyle: "italic"}}>{children}</Typography>
}

export const Text = ({children})=>{
  return <Typography component={'span'} variant="body1" style={{fontWeight: "400", fontSize: "15px"}}>{children}</Typography>
}

export const CalendarItem = ({children})=>{
  return <Typography component="h1" style={{fontWeight: "600", fontSize: "20px", lineHeight: "22px", textTransform: "uppercase"}}>{children}</Typography>
}

export const MenuItem = ({children})=>{
  return <Typography component="h1" style={{fontWeight: "700", fontSize: "45px", lineHeight: "48px"}}>{children}</Typography>
}
export const Social = ({children})=>{
  return <Typography style={{textTransform: "uppercase", fontSize: "35px", fontWeight: "700", lineHeight: "37px"}}>{children}</Typography>
}

// button

export const Button = ({children, to, external})=>{

    const B = <CalendarItem> {children} </CalendarItem>

    if (to){
    if (external){
      return <a href={to} target="_blank">{B}</a>
    } else {
      console.log(to)

      return <Link to={ (to._type)?`/${to._type}/`+to.slug:to}>
        {B}
    </Link>
    }
  } else {
    return B;
  }
}

//   color = color?color:defaultTheme.palette.primary;
//   const B = <MButton  color="primary" variant="hard" style={{color:color, borderColor: color, textDecoration: "none", margin: "5px"}}>
//   {children}
// </MButton>

//   if (to){
//     if (external){
//       return <a href={to} target="_blank">{B}</a>
//     } else {
//       return <Link to={to}>
//         {B}
//     </Link>
//     }
//   } else {
//     return B;
//   }
// }

export const CollaboratorList = ({collaborators})=>{
  

  if (!collaborators || !collaborators.length){
    return null;
  }

  return <div style={{marginTop: "10px"}}><Text> <b> With:&nbsp;

          {collaborators.map((c, i, {length}) => {
              const name = <a key={i} href={c.url} target="_blank">{c.title}</a>
              if (length - 1 === i) {
                  return <span key={name}>{name}</span> 
              } else {
                return <span>{name}, </span> 
              }
          })}


    {/* {collaborators.map((c, i, collaborators)=>{
      <a href={c.url} target="_blank">{c.title}</a>
    
    })} */}
  </b>
  </Text></div>
}

export const ButtonList = ({buttons})=>{
  if (!buttons || !buttons.length){
    return null;
  }

  return buttons.map(({_key, title, url, target})=>{
    console.log(title, url, target)

    return <div key={_key} style={{marginTop: "10px"}}> 
              <Button to={url?url:target} external={url?true:false}> ☞ {title} </Button> 
            </div>}) 
}

//   media 
export const LookupResponsiveimage = ({id})=>{
  const graphics = useSelector(state=>state.data.graphics)
  if (graphics){
    if (graphics[id] && graphics[id].url){
      return <ResponsiveImage src={graphics[id].url} />
    } else {
      return null
    }
  } else {
    return null
  }
}

export const ResponsiveImage = ({src})=>{
    return <img src={src} style={{width: "100%"}} />
}

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


export const getAcademicImages = (graphics)=>{
  const ls = []
  if (graphics){
    for (const key in graphics) {
      if (Object.hasOwnProperty.call(graphics, key)) {
        const graphic = graphics[key];
        for (let index = 0; index < graphic.related.length; index++) {
          const element = graphic.related[index];
          if (element._type == "publication"){
            ls.push(graphic.url)
          } 
        }
      }
    }
    return shuffle(ls);
  } else {
    return null
  }
}

export const get = (graphics)=>{
  const ls = []
  if (graphics){
    for (const key in graphics) {
      if (Object.hasOwnProperty.call(graphics, key)) {
        const graphic = graphics[key];
        for (let index = 0; index < graphic.related.length; index++) {
          const element = graphic.related[index];
          if (element._type == "publication"){
            ls.push(graphic.url)
          } 
        }
      }
    }
    return shuffle(ls);
  } else {
    return null
  }
}

export const LookupImageList = ({graphiclist})=>{

  const graphics = useSelector(state=>state.data.graphics)

  if (!graphiclist || !graphiclist.length){
    return null;
  
  }
  

  
  if (graphics){

  return <ImageList variant="masonry" cols={1} gap={8}>
  {graphiclist.map((i) => {

  const item = graphics[i].url;


  return (
    <ImageListItem key={item}>
      <img
        srcSet={`${item}`}
        src={`${item}`}
        alt={item}
        loading="lazy"
      />
    </ImageListItem>
  )})}
</ImageList>

  } else {
    return null
  }
}

export const Space = ()=><>&nbsp;</>

// contianers
export const Box = ({children})=>(
  <div style={{margin: "50px"}}>
    {children}
  </div>
)


const defaults = {nonTextBehavior: 'remove'}

export function blocksToText(blocks, opts = {}) {
  const options = Object.assign({}, defaults, opts)
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return options.nonTextBehavior === 'remove' ? '' : `[${block._type} block]`
      }

      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}
  
export default theme;

