import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, CalendarItem, LookupResponsiveimage, ButtonList, getAcademicImages, LookupImageList } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid, ImageList, ImageListItem, Typography } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';
import { Subtitles } from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Masonry from '@mui/lab/Masonry';

import Paper from '@mui/material/Paper';



// TODO: implement

// const Intro = ()=>{
//     const settings = useSelector(state=>state.data.settings);

//     if (settings){
//         return <div>
//             <SubTitle>
//                 <BlockContent blocks={settings.projectsText} />
//             </SubTitle>

//             <br/>
//             <ButtonList buttons={settings.projectButtons} />

//         </div>
//     } else {
//         return null;
//     }

// }

// const ProjectImages = ()=>{
//     const graphics = useSelector(state=>state.data.graphics)
//     const [images, setImages] = useState(null);

//     useEffect(()=>{
//         if (graphics && !images){
//             setImages(getAcademicImages(graphics));
//         }
    
//     })

    

//     if (images){
//         return <ImageList variant="masonry" cols={1} gap={8}>
//         {images.map((item) => (
//           <ImageListItem key={item}>
//             <img
//               srcSet={`${item}`}
//               src={`${item}`}
//               alt={item}
//               loading="lazy"
//             />
//           </ImageListItem>
//         ))}
//       </ImageList>
//     } else {
//         return null
//     }
// }

const Tile = ({slug, header, title, banner})=>{
    const client = useSelector(state=>state.data.client);

    if (!client){
        return null;
    }

    const txt = <div><Text> <b> {banner} / {banner} / {banner} / {banner} / {banner} /</b> </Text></div>

    return <Grid item xs={12} sm={6} md={4} lg={3}>
        

        <div className="wrapperHeader">
              <div className="marquee">
                <div>
                {txt}
                </div>
                <div>
                {txt}
                </div>
              </div>
            </div>
        
        
        
        <Link to={`/project/${slug}`}>
            <img src={client.squareImage(header, "900")} style={{width: "100%"}} />
            <div style={{textAlign: "center"}}><SubTitle><span >{title}</span></SubTitle></div>
        </Link>
    </Grid>
}



const Projects = ()=>{
    const settings = useSelector(state=>state.data.settings);
    const category = useSelector(state=>state.data.category)

    const Meta = <MetaTags>
                    <title>{"Pieter Steyaert / Projects"}</title>
                    <meta name="description" content={"Project overview of Pieter, who is an artist and researcher based in Copenhagen, Denmark"} />
                    <meta property="og:title" content={"Pieter Steyaert / Projects"} />
                </MetaTags>

    if (!settings){
        return null
    }
    
    
    return <> 
        {Meta}
        <Grid container spacing={3} style={{marginTop: "20px"}}>
            {/* <Grid item lg={1} md={0} sm={0} sx={{display: {xs : "none", sm: "none", md: "none", lg: "block"}}}  />
            <Grid item xs={12} sm={12} lg={10} >
                <Intro />
            </Grid> */}
            
            {/* <ProjectList /> */}
            
            
            {settings.projects
            .filter((i)=>{
                return !category || !i.categories || !i.categories.length || i.categories.includes(category) 
            return true;
            })
            .map((i)=>{     
                return <Tile key={i.slug} slug={i.slug} header={i.header} title={i.title} banner={i.banner}/>
            })}

            
            
            
            
            
            
            </Grid>

        
            {/* <HomeGraphicsMobile /> */}
        
        
    </>
}



export default Projects;
