import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, CalendarItem, LookupResponsiveimage, ButtonList, getAcademicImages, LookupImageList } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid, ImageList, ImageListItem, Typography } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';
import { Block, Subtitles } from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Masonry from '@mui/lab/Masonry';

import Paper from '@mui/material/Paper';



const Contact = ()=>{
    const settings = useSelector(state=>state.data.settings);

    if (settings){

    const Meta = <MetaTags>
                    <title>{"Pieter Steyaert / Contact"}</title>
                    <meta name="description" content={"Contact Pieter, who is an artist and researcher based in Copenhagen, Denmark"} />
                    <meta property="og:title" content={"Pieter Steyaert / Contact"} />
                </MetaTags>
    
    
    return <> 
        {Meta}
        <Grid container spacing={20}>
            <Grid item xs={12} sm={12} lg={6}>
                <Title>/ Contact</Title>
                <Text><BlockContent blocks={settings.contact} /></Text>
            </Grid>    
        </Grid>

        
            {/* <HomeGraphicsMobile /> */}
    </>
 } else {
    return null;
}

}



export default Contact;
