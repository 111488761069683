import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Spinner from "./spinner";
import { MenuItem, Social, Text} from "../theme/theme";
import { Link } from "react-router-dom";
import 'animate.css';
import { useLocation } from 'react-router-dom';
import { Box } from "@mui/material";
import { ACTIONS as DATA_ACTIONS } from "../redux/reducers/DataReducer"


const Menu = ()=>{
    const settings = useSelector(state=>state.data.settings)
    const categories = useSelector(state=>state.data.categories)
    const category = useSelector(state=>state.data.category)
    const [pathname, setPathname] = useState(undefined);
    const [changed, setChanged] = useState(false);
    const [socials, setSocials] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(()=>{
        const pn = window.location.pathname
        if (pn !== pathname){
            setPathname(pn)
            setSocials(false)
        }
    }, [location])

    if (!settings || !pathname || !categories){
        return <div>
           __)\_  
     (\_.-'    a`-.
     (/~~````(/~^^`
            </div>
    }

    const items = [{
        text: "P S",
        target: "/"
    }, {
        text: "Publications",
        target: "/publications"
    },{
        text: "Projects",
        target: "/projects"
    },{
        text: "P contact",
        target: "/contact"
    }]

    const setCategory = (category)=>{
        dispatch({type: DATA_ACTIONS.SET_CATEGORY, category});
    }
    

    return <>
        <div className="menu">
            {items.map(({text, target})=>{

                const projectOpen = pathname.indexOf("/project/")!==-1
                const publicationOpen = pathname.indexOf("/publication/")!==-1
                const projectName = pathname.replace("/project/", "");
                const publicationName = pathname.replace("/publication/", "");

                const content = <Link to={target} key={text} onClick={()=>{setChanged(!changed)}}>
                <MenuItem>
                    <span style={{textDecoration: (pathname==target)?"underline":""}} className="outline">{text}</span>
                </MenuItem>
                </Link>

                if (target == "/projects"){
                    return  <span key={target}>
                        {content}
                        {(pathname==target || projectOpen)? <div >
                        <div style={{fontSize: "3px"}}>&nbsp;</div>

                        {!projectOpen?<div>
                            <Social> <span style={{textDecoration: (!category)?"underline":""}} onClick={()=>{setCategory(null)}}> ☞ All Projects</span></Social>                        
                            {categories.map(({title})=><Social key={title} > <span onClick={()=>{setCategory(title)}} className="outline" style={{textDecoration: (title == category)?"underline":""}}>☞ {title}</span></Social>)}
                            <br/>
                        </div>:null}
                        
                        {settings.projects.map((i)=>{ 
                            return <Box component={Link} sx={{ display: { xs: 'none', sm: 'none', md: "block" } }} key={i.slug} to={`/project/${i.slug}`} >
                                 <Social> ⛉  <span className="outline" style={{textDecoration: (projectName==i.slug)?"underline":""}}>{i.slug}</span></Social>
                            </Box>})} </div> : null}
                            {/* </Box> */}
                            
                            {(pathname == "/projects")?<Box component={"br"} sx={{ display: { xs: 'none', sm: 'none', md: "block" } }} /> : null}
                    </span>
               } else if (target == "/publications"){
                    return  <span key={target}>
                        {content}
                        {(pathname==target || publicationOpen)? <div >
                        <div style={{fontSize: "3px"}}>&nbsp;</div>
                        
                        {settings.publications.map((i)=>{ 
                            return <Box component={Link} sx={{ display: { xs: 'none', sm: 'none', md: "block" } }} key={i.slug} to={`/publication/${i.slug}`} >
                                 <Social> ♢  <span className="outline" style={{textDecoration: (publicationName==i.slug)?"underline":""}}>{i.slug}</span></Social>
                            </Box>})} </div> : null}
                            {/* </Box> */}
                            
                            {(pathname == "/publications")?<Box component={"br"} sx={{ display: { xs: 'none', sm: 'none', md: "block" } }} /> : null}
                    </span>
                } else {
                    return content
                }

            })}

            <MenuItem> <span onClick={()=>{setSocials(!socials)}} className="outline">Socials</span></MenuItem>
            {socials?<div style={{marginLeft: "30px"}} className="animate__animated animate__bounceInLeft"> 
                <div style={{fontSize: "1px"}}>&nbsp;</div>
                {settings.socials.map(({title, url})=><div key={title}> 
                <a href={url} target="_blank"> <Social> ☞  <span className="outline">{title}</span></Social></a>
                
                    
                </div>)}

            </div>: null}

            <br/>

        </div>
    </>
}

export default Menu;