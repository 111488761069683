import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, CalendarItem, LookupResponsiveimage } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid, ImageList, Typography } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';
import { Subtitles } from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Masonry from '@mui/lab/Masonry';

import Paper from '@mui/material/Paper';


// TODO: implement

const Profile = ()=>{
    const settings = useSelector(state=>state.data.settings)

    if (settings){
        return <div> <div style={{padding: "10%"}}>
            <img src={settings.header} style={{width: "80%"}} />
            <br /><br />
            </div>
            <Title> / Pieter Steyaert</Title>
            <Text>
                <BlockContent blocks={settings.intro} />
            </Text>
        </div>
    } else {
        return <></>
    }
}

const Calendar = ()=>{
    const settings = useSelector(state=>state.data.settings)

    if (settings){
        return <div> 
            <Title> / Calendar </Title>



            {settings.calendar.map(({_key, title, url, target})=>{
                return <div  key={_key} style={{marginTop: "20px"}}> <Button to={url?url:target} external={url?true:false}> {title} </Button> </div>
                })}
        <br/><br/><br/>
        </div>
    } else {
        return <></>
    }
}



const HomeGraphics = ()=>{
    const settings = useSelector(state=>state.data.settings)
    const [images, setImages] = useState(null)
    var counter = 0;

    useEffect(()=>{


        if (settings && !images){
            const nl = [];


            settings.images.forEach(i => {
                for (var j = 0; j < 3; j++) {
                    if (Math.random() < 0.9){
                        // nl.push("black")
                    } 
                }

                nl.push(i)
            });
            setImages(nl)
        
        }
    })

    if (settings && images){
        return  <div>
            <Masonry columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3 }}  spacing={5}>

        { 
        images.map(i=> { 
            
        counter++;
        return <div key={counter} style={{marginBottom: `${Math.floor(Math.random()*600)}px`, marginTop: `${Math.floor(Math.random()*600)}px`}}>
                    {i == "black"? null : <LookupResponsiveimage id={i} />}
              </div> })}


      </Masonry>

      </div>
    } else {
        return <></>
    }
}

const HomeGraphicsMobile = ()=>{
    const settings = useSelector(state=>state.data.settings)

    if (settings){
        return  <ImageList sx={{width: "100%", display: {xs: "inline", sm: "inline", md: "none"}}} style={{backgroundColor: "black"}}>
                    {settings.images.reverse().map(i=> <div key={i} style={{marginBottom: "20px"}}><LookupResponsiveimage id={i} style={{marginBottom: "10px"}} /></div>)    }
        </ImageList>
    } else {
        return null
    }
}

const News = ()=>{
    const maxNews = 3;

    const settings = useSelector(state=>state.data.settings);
    const [amnt, setAmnt] = useState(maxNews);

    const clicker=()=>{
        setAmnt(amnt+maxNews)
    }
    
    if (settings){
        return <div> 
            <Title> / News </Title>
            <br/>
            {settings.news.slice(0, amnt).map(({_key, title, graphic, buttons, description})=>{
                return <div key={_key}>
                    <SubTitle>{title}</SubTitle>
                    {description? <Text> <BlockContent blocks={description} /> </Text> : null}
                    {buttons? buttons.map(({_key, title, url, target})=>{
                        return <div key={_key} style={{marginTop: "10px"}}> <Button to={url?url:target} external={url?true:false}> ☞ {title} </Button> </div>}) : null}
                    {graphic? <LookupResponsiveimage id={graphic} /> : null}
                <br /><br /><br />
                </div>})}

            {(settings.news.length > amnt)? <div onClick={clicker}> <Button onClick={clicker}> ... more news </Button> </div>: null}
        </div>
    } else {
        return <></>
    }
}

const Home = ()=>{

    const Meta = <MetaTags>
                    <title>{"Pieter Steyaert / Home"}</title>
                    <meta name="description" content={"Pieter is an artist and researcher based in Copenhagen, Denmark, working at the intersection of art and science. He co-founded the collective SEADS, known for community art projects like Biomodd. Pieter is involved in projects such as sending an evolutionary artwork into space and co-directing immersive theatre experiences. He also develops online platforms, creates software for live visual performances, and is pursuing a PhD in facilitating collaborations between arts and sciences."} />
                    <meta property="og:title" content={"Pieter Steyaert / Home"} />
                </MetaTags>
    
    
    return <> 
    {Meta}
        <Grid container spacing={20}>
            <Grid item xs={12} sm={12} lg={6}>
                <Profile />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <Calendar />
                <News />
            </Grid>
            
            <Grid item xs={12} sx={{display: {xs : "none", sm: "none", md: "block"}}}>
                <HomeGraphics />
            </Grid>
            
            
            </Grid>

        
            <HomeGraphicsMobile />
        
        
    </>
}



export default Home;
